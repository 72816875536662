// Подтягиваем валидный id статуса по alias из query
// (для формы регистрации или oauth)
// active: false для отключения

import Vue from "vue"
import { mapState, mapActions } from "vuex"
import type { Status } from "@/types/api/event"

export default (active = true) => Vue.extend({
  computed: {
    ...mapState("app", ["statuses"]),
  },

  methods: {
    ...mapActions("app", { fetchStatuses: "FETCH_STATUSES" }),

    async getStatusFromQuery (): Promise<string | undefined> {
      if (!active) return undefined

      await this.fetchStatuses()
      const statuses = this.statuses as Status[]

      const alias =
        this.$route.query.status ||
        statuses[0]?.alias ||
        "hraniteli-trop"

      const id = statuses.find(s => s.alias === alias)?.id || undefined

      // console.log({ alias, id })

      return id
    },
  },
})
