import { render, staticRenderFns } from "./UserForm.vue?vue&type=template&id=18b02e64&"
import script from "./UserForm.vue?vue&type=script&lang=ts&"
export * from "./UserForm.vue?vue&type=script&lang=ts&"
import style0 from "./UserForm.vue?vue&type=style&index=0&id=18b02e64&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtn: require('/app/components/app/AppBtn.vue').default,WidgetForm: require('/app/components/widget/WidgetForm.vue').default})
